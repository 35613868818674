export default {
    data() {
        return {
            payment_categories: [],
        }
    },

    methods: {
        initPaymentCategories() {
            this.fetchPaymentsCategories()
                .then((payment_categories) => {
                    this.payment_categories = payment_categories;
                });
        },

        fetchPaymentsCategories(params = {}) {
            return new Promise((resolve, reject) => {
                this.$http.get('admin/accounting/payment_category', params)
                    .then(({body}) => {
                        resolve(body.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    }
}
